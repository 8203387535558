import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const BrandList = [
    {
        image: '../images/brand/logo-verizon.png',
        height: 70
    },
    {
        image: '../images/brand/logo-charter.png',
        height: 60
    },
    {
        image: '../images/brand/logo-askey.png',
        height: 70
    },
    {
        image: '../images/brand/logo-tmobile.png',
        height: 40
    },
    {
        image: '../images/brand/logo-opplane.png',
        height: 90
    },
    {
        image: '../images/brand/logo-rachna-infotek.png',
        height: 60
    },
    {
        image: '../images/brand/logo-tollsecure.jpeg',
        height: 70
    },
    {
        image: '../images/brand/logo-bel.png',
        height: 60
    }
]

const BrandFive = ({brandStyle}) => {
    return (
        <ScrollAnimation 
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}>
        <ul className={`brand-list ${brandStyle}`}>
            
            {BrandList.map((data, index) => (
                <li key={index} className="custom-brand-li">
                    <div><img src={`${data.image}`} alt="Brand Image" style={{height: data.height, filter: "grayscale(100%)"}}/></div>
                    {/* <div className="pt--20" style={{color: '#000', fontWeight: 'bold'}} dangerouslySetInnerHTML={{__html: data.stack}}></div> */}
                </li>
            ))}
            
        </ul>
        </ScrollAnimation>
    )
}

export default BrandFive;
