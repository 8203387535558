import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiActivity />,
        title: 'RDK / RDKB',
        description: 'Our team members were part of the core RDKB team at Comcast SVI center in California. They have led the RDK worldwide support on behalf of the RDK, LLC.'
    },
    {
        icon: <FiCast />,
        title: 'openSync',
        description: "Our team is working with Charter on their WiFi6 and Wave2 routers, which are based on Plume's opensync software stack."
    },
    {
        icon: <FiMap />,
        title: 'openWrt',
        description: 'We have extensive experience in building Qualcomm based Wave2 and WiFi6 broadband routers.'
    },
    {
        title: 'CPE Containers',
        icon: <FiActivity />,
        description: 'Whether it is about building a LXC infrastructure or K8 on the CPE device. We have done it all.'
    },
    {
        title: 'Easy Mesh',
        icon: <FiCast />,
        description: 'This trending technology is our speciality. We have a complete understanding of the Qualcomm easy mesh stack.'
    },
    {
        title: 'Open AI',
        icon: <FiCast />,
        description: 'This proprietary WiFi mesh technology powers many popular mesh routers and extenders. We have got you covered.'
    },
    {
        title: 'TR181',
        icon: <FiActivity />,
        description: 'Our team has done TR 181 data model implementation on Intel, Broadcom and Qualcomm platforms.'
    },
    {
        title: 'CPE Telemetry',
        icon: <FiCast />,
        description: 'We have an inhouse LXC based containerized telemetry application which can be easily ported to any platform for data collection.'
    },
    {
        title: 'BLE',
        icon: <FiCast />,
        description: "We have helped multiple ISP's simplify IoT devices onboarding on WiFi network for their customer's. "
    },
    {
        title: 'WiFi Analytics',
        icon: <FiActivity />,
        description: "Powered by LXC based containerized application, our cloud based analytics platform provides a powerful tool for ISP's customer care agents."
    },
    {
        title: 'TR181',
        icon: <FiActivity />,
        description: 'Our team has done TR 181 data model implementation on Intel, Broadcom and Qualcomm platforms.'
    },
    {
        title: 'CPE Telemetry',
        icon: <FiCast />,
        description: 'We have an inhouse LXC based containerized telemetry application which can be easily ported to any platform for data collection.'
    },
    {
        title: 'BLE',
        icon: <FiCast />,
        description: "We have helped multiple ISP's simplify IoT devices onboarding on WiFi network for their customer's. "
    },
    {
        title: 'WiFi Analytics',
        icon: <FiActivity />,
        description: "Powered by LXC based containerized application, our cloud based analytics platform provides a powerful tool for ISP's customer care agents."
    },

]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper" style={{display: 'flex',justifyContent: 'center'}}>
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`} style={{minHeight: 312}}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;
