import {useState, useRef } from "react";
import React from 'react'
import { FiMenu } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import Nav from './NavNew';
import MobileMenu from './MobileMenuNew';
import Darkmode from "./Darkmode";
import useStickyHeader from "./useStickyHeader";


const HeaderOneNew = ({btnStyle, HeaderSTyle}) => {
    let logoSet = false;
    let logoFileName = `/images/logo/logo-ra-light.png`;
    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    const onCanvasHandler = () => {
        setOffcanvasShow(prev => !prev);
    }
    const ref = useRef();
    let [check, setCheck] = useState(true);
    const sticky = useStickyHeader( 50 );
    const headerClasses = `header-default ${(sticky && check) ? 'sticky' : ''}`
    if(sticky) { 
        logoFileName = `/images/logo/logo-ra.png`; 
    }
    logoSet = true;
    const { clientHeight } = ref;
    
    const checkChange = (value) => {
      setCheck(value);
    };
    return (
        <div>
            <header ref={ref} className={`rn-header header-default ${HeaderSTyle} ${headerClasses}`}>
                <div className="container position-relative">
                    <div className="row align-items-center row--0">
                        <div className="col-lg-3 col-md-6 col-4">
                            {!logoSet && <Logo 
                                // image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                                // image2={`${process.env.PUBLIC_URL}/images/logo/logo-infernet.png`}
                                image={`${process.env.PUBLIC_URL}/images/logo/logo-ra.png`}
                            />}
                            {logoSet && <Logo 
                                // image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                                // image2={`${process.env.PUBLIC_URL}/images/logo/logo-infernet.png`}
                                image={`${logoFileName}`}
                            />}
                            
                        </div>
                        <div className="col-lg-9 col-md-6 col-8 position-static">
                            <div className="header-right">
                                <nav className="mainmenu-nav d-none d-lg-block">
                                    <Nav />
                                </nav>
                                {/* <div className="header-btn">
                                    <a className={`btn-default ${btnStyle}`} target="_blank" href="https://themeforest.net/checkout/from_item/33571911?license=regular">BUY NOW</a>
                                </div> */}
                                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                                    <div className="hamberger">
                                        <span className="hamberger-button" onClick={onCanvasHandler}><FiMenu /></span>
                                    </div>
                                </div>
                                <Darkmode />
                            </div>  
                        </div>
                    </div>
                </div>
            </header>
            <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler}  />
        </div>
    )
}
export default HeaderOneNew;